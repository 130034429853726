import * as CookieConsent from 'vanilla-cookieconsent'

try {
	$(document).ready(function () {
		const data = JSON.parse($('#cookie-consent').text())

		const { modal, essential, analytics, info } = data

		CookieConsent.run({
			guiOptions: {
				consentModal: {
					layout: 'box wide',
					position: 'middle center',
					equalWeightButtons: true,
					flipButtons: false,
				},
				preferencesModal: {
					layout: 'box',
					position: 'right',
					equalWeightButtons: true,
					flipButtons: false,
				},
			},
			categories: {
				necessary: {
					readOnly: true,
				},
				analytics: {},
			},
			language: {
				default: 'en',
				autoDetect: 'browser',
				translations: {
					en: {
						consentModal: {
							title: modal.title,
							description: modal.description,
							acceptAllBtn: modal.acceptAll,
							acceptNecessaryBtn: modal.acceptNecessary,
							showPreferencesBtn: modal.descriptionButton,
							footer:
								'<a href="/contact/privacy-policy">Privacy Policy</a>\n<a href="/contact/terms-and-conditions">Terms and conditions</a>',
						},
						preferencesModal: {
							title: modal.preference,
							acceptAllBtn: modal.acceptAll,
							acceptNecessaryBtn: modal.acceptNecessary,
							savePreferencesBtn: modal.saveSettings,
							closeIconLabel: modal.close,
							serviceCounterLabel: 'Service|Services',
							sections: [
								{
									title: `${modal.blockTitle} 📢`,
									description: `${modal.blockDescription} <a href="/contact/privacy-policy" class="cc-link">privacy policy</a>.`,
								},
								{
									title: essential.title,
									description: essential.description,
									linkedCategory: 'necessary',
								},
								{
									title: analytics.title,
									description: analytics.description,
									linkedCategory: 'analytics',
								},
								{
									title: info.title,
									description: `${info.description} <a class="cc__link" href="/contact">${info.contact}</a>.`,
								},
							],
						},
					},
				},
			},
		})
	})
} catch (e) {
	// eslint-disable-next-line no-console
	console.error('cookie consent could not initialise', e)
}
